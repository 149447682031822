<template>
  <div class="popup-wraper" @click="onHideModal">
    <div class="popup-overlay aep" @click.stop>
      <div class="popup-overlay-title aep">
        <img
            alt="img"
            src="@/assets/img/publish_as_community.svg"
            style="max-height: 22px;"
        />
        <span class="popup-overlay-title-span">edit contribution</span>
      </div>
      <div class="popup-scroll aep" ref="dropZone">
        <div class="add-tag-contanier" v-if="catalogTab === 'categories'">
          <!-- status -->
          <div class="type aep-select-field" v-if="isEditor">
            <el-dropdown trigger="click">
              <div class="aep-filter-select">
                <div class="aep-select-container">
                  {{ item.status }}
                  <img
                      alt="img"
                      src="@/assets/img/select_arrow.svg"
                      class="p-arrow"
                  />
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="aep-aep-select-list">
                  <div
                      class="aep-select-item"
                      v-for="(p, idx) of ['on review', 'validated', 'rejected']"
                      :key="idx"
                      @click="item.status = p"
                  >
                    {{ p }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="aep-field-name">status</div>
          </div>
          <!-- name -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.name"
            />
            <div class="aep-field-name ">name</div>
          </label>
          <!-- position -->
          <label v-if="isEditor" style="margin-top: 20px;">
            <input
                class="aep-search-input"
                type="number"
                v-model.number="item.position"
            />
            <div class="aep-field-name ">position</div>
          </label>
          <!-- comment -->
          <textarea class="aep-comment-text" v-model="itemComment"></textarea>
          <div v-if="!isEditor">
            <span class="aep-field-name">comment (optional)</span>
          </div>
        </div>
        <!-- templates / images / text -->
        <div
            class="aep-add-element"
            v-else-if="
            catalogTab === 'templates' ||
              catalogTab === 'texts' ||
              catalogTab === 'images'
          "
        >
          <div class="drop-effect"></div>
          <!-- size -->
          <div class="aep-size">{{ getSizeInMBs(item.size) }}</div>
          <!-- review -->
          <div v-if="catalogTab.match(/templates|texts/)">
            <div class="aep-image-container">
              <img
                  alt="img"
                  class="aep-review-img"
                  :src="item.newImage || item.img"
              />
            </div>
            <div
                v-show="catalogTab !== 'images'"
                @click.stop="editContent"
                class="edit-contribution-btn"
            >
              edit
            </div>
          </div>
          <label
              v-else
              class="aep-image-container"
              style="margin-bottom: 25px;"
              :class="{ 'aep-upload-container':
              !item.content
              && !item.img
              && !item.newContent
              && !item.newImage
            }"
          >
            <img
                alt="img"
                class="aep-review-img"
                :src="
                item.img || item.newImage || item.content || item.newContent ||
                 require('@/assets/img/upload.svg')
              "
            />
            <input
                type="file"
                v-if="isEditor"
                @change="onUploadImage"
                accept="image/svg+xml"
                hidden
            />
          </label>
          <!-- categories -->
          <div class="type aep-select-field">
            <div class="aep-filter-select" style="max-width: 460px;">
              <el-dropdown trigger="click">
                <div class="aep-select-container">
                  <div class="aep-select-text">
                    {{ getCtgs(item.categories) }}
                  </div>
                  <img
                      alt="img"
                      src="@/assets/img/select_arrow.svg"
                      class="p-arrow"
                  />
                </div>
                <el-dropdown-menu
                    class="categories-dropdown-menu"
                    slot="dropdown"
                >
                  <input
                      type="text"
                      class="aep-search-input"
                      v-model="ctgsFilterInput"
                      @click.stop
                  />
                  <div class="aep-aep-select-list">
                    <label
                        class="checkbox-container filters"
                        v-for="(c, idx) of categoriesListMod"
                        :key="idx"
                    >
                      <input
                          type="checkbox"
                          hidden
                          @change="updateCtgList(item.categories, c)"
                          :checked="isCheckedCategory(item.categories, c)"
                      />
                      <div class="checkbox-item"></div>
                      <span>{{ c.name }}</span>
                    </label>
                    <div @click.stop="goToAddCategory" class="aep-new-category">
                      <img
                          alt="img"
                          class="aep-new-category-img"
                          src="@/assets/img/add_btn.svg"
                      />
                      <span>contribute a new category</span>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="aep-field-name ">categories</div>
          </div>
          <!-- status -->
          <div class="type aep-select-field" v-if="isEditor">
            <el-dropdown trigger="click">
              <div class="aep-filter-select">
                <div class="aep-select-container">
                  {{ item.status }}
                  <img
                      alt="img"
                      src="@/assets/img/select_arrow.svg"
                      class="p-arrow"
                  />
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="aep-aep-select-list">
                  <div
                      class="aep-select-item"
                      v-for="(p, idx) of ['on review', 'validated', 'rejected']"
                      :key="idx"
                      @click="item.status = p"
                  >
                    {{ p }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="aep-field-name ">status</div>
          </div>
          <!-- copyright -->
          <label class="manager-label" v-if="catalogTab === 'images'">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.copyright"
            />
            <div class="aep-field-name">copyright</div>
          </label>
          <!-- name -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.name"
            />
            <div class="aep-field-name ">name</div>
          </label>
        </div>
        <!-- pages -->
        <div class="aep-add-element" v-else-if="catalogTab === 'pages'">
          <!-- review -->
          <div class="aep-image-container">
            <img alt="img" class="aep-review-img" :src="item.img"/>
            <!-- <div @click.stop="editContent" class="edit-contribution">
              edit
            </div> -->
          </div>
          <!-- size -->
          <div class="aep-field-name">{{ getSizeInMBs(item.size) }}</div>
          <!-- visibilty -->
          <label class="checkbox-container">
            <input type="checkbox" hidden v-model="item.visibility"/>
            <div class="checkbox-item"></div>
          </label>
          <div class="aep-field-name ">visibilty</div>
          <!-- set -->
          <div class="type aep-select-field">
            <el-dropdown trigger="click">
              <div class="aep-filter-select" style="max-width: 460px;">
                <div class="aep-select-container">
                  <div class="aep-select-text">
                    {{ item.interaction && item.interaction.name }}
                  </div>
                  <img
                      alt="img"
                      src="@/assets/img/select_arrow.svg"
                      class="p-arrow"
                  />
                </div>
              </div>
              <div class="aep-field-name ">
                interaction
              </div>
              <el-dropdown-menu
                  class="interactions-dropdown-menu"
                  slot="dropdown"
              >
                <input
                    type="text"
                    class="aep-search-input"
                    v-model="interactionsFilterInput"
                    @click.stop
                />
                <div class="aep-aep-select-list">
                  <div
                      class="aep-select-item"
                      v-for="(s, idx) of [
                      { name: '-- none --' },
                      ...interactionsListMod
                    ]"
                      :key="idx"
                      @click.stop="
                      item.interaction = s.name === '-- none --' ? null : s
                    "
                  >
                    <span>{{ s.name }}</span>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- name -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.name"
            />
            <div class="aep-field-name ">name</div>
          </label>
        </div>
        <!-- fonts -->
        <div class="aep-add-element" v-else-if="catalogTab === 'fonts'">
          <!-- review -->
          <div class="aep-image-container">
            <div
                class="aep-font-review"
                :style="{ 'font-family': item.fontFamily }"
            >
              {{ item.name }}
            </div>
          </div>
          <!-- size -->
          <div class="aep-field-name">{{ getSizeInMBs(item.size) }}</div>
          <!-- status -->
          <div class="type aep-select-field" v-if="isEditor">
            <el-dropdown trigger="click">
              <div class="aep-filter-select">
                <div class="aep-select-container">
                  {{ item.status }}
                  <img
                      alt="img"
                      src="@/assets/img/select_arrow.svg"
                      class="p-arrow"
                  />
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="aep-aep-select-list">
                  <div
                      class="aep-select-item"
                      v-for="(p, idx) of ['on review', 'validated', 'rejected']"
                      :key="idx"
                      @click="item.status = p"
                  >
                    {{ p }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="aep-field-name">status</div>
          </div>
          <!-- name -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.name"
            />
            <div class="aep-field-name ">name</div>
          </label>
          <!-- copyright -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.copyright"
            />
            <div class="aep-field-name">copyright</div>
          </label>
          <textarea class="aep-comment-text" v-model="itemComment"></textarea>
          <div v-if="!isEditor">
            <span class="aep-field-name">comment (optional)</span>
          </div>
        </div>
        <div class="aep-add-element" v-else-if="catalogTab === 'users'">
          <!-- review -->
          <div class="aep-image-container" style="margin-bottom: 30px;">
            <img
                alt="img"
                class="aep-review-img"
                :src="item.avatar || require('@/assets/img/defaultUser.png')"
            />
          </div>
          <!-- name -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.name"
            />
            <div class="aep-field-name">name</div>
          </label>
          <!-- email -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.email"
            />
            <div class="aep-field-name">email</div>
          </label>
          <!-- limits -->
          <div class="user-limits-container">
            <label class="manager-label">
              <input
                  type="number"
                  class="input aep manager-input"
                  v-model.number="userMemoryLimit"
                  placeholder="100"
              />
              <div class="aep-field-name">MBs</div>
            </label>
          </div>
          <!-- role -->
          <el-dropdown trigger="click" placement="bottom">
            <div class="aep-filter-select">
              <div class="aep-select-container">
                <div class="select-input">
                  {{ item.role }}
                </div>
                <img
                    alt="img"
                    src="@/assets/img/select_arrow.svg"
                    class="p-arrow"
                />
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="aep-aep-select-list">
                <div
                    class="aep-select-item"
                    v-for="(l, idx) of [
                    'regular',
                    'regular/no-publish',
                    'editor'
                  ]"
                    :key="idx"
                    @click="item.role = l"
                >
                  {{ l }}
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="aep-field-name">role</div>
          <!-- active -->
          <el-dropdown trigger="click" placement="bottom">
            <div class="aep-filter-select">
              <div class="aep-select-container">
                <div class="select-input">
                  {{ item.active }}
                </div>
                <img
                    alt="img"
                    src="@/assets/img/select_arrow.svg"
                    class="p-arrow"
                />
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="aep-aep-select-list">
                <div
                    class="aep-select-item"
                    v-for="(l, idx) of ['active', 'blocked']"
                    :key="idx"
                    @click="item.active = l"
                >
                  {{ l }}
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="aep-field-name">active</div>
          <!-- confirm email -->
          <el-dropdown trigger="click" placement="bottom">
            <div class="aep-filter-select">
              <div class="aep-select-container">
                <div class="select-input">
                  {{ item.confirm_email }}
                </div>
                <img
                    alt="img"
                    src="@/assets/img/select_arrow.svg"
                    class="p-arrow"
                />
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div
                  class="aep-select-item"
                  v-for="(l, idx) of [true, false]"
                  :key="idx"
                  @click="item.confirm_email = l"
              >
                {{ l }}
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="aep-field-name">confirm</div>
          <!-- city -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.city"
            />
            <div class="aep-field-name">City</div>
          </label>
          <!-- country -->
          <el-dropdown trigger="click" placement="bottom">
            <div class="aep-filter-select">
              <div class="aep-select-container">
                <div class="select-input">
                  {{ countryName }}
                </div>
                <img
                    alt="img"
                    src="@/assets/img/select_arrow.svg"
                    class="p-arrow"
                />
              </div>
            </div>
            <el-dropdown-menu class="dropdown-menu" slot="dropdown">
              <div
                  class="aep-select-item"
                  v-for="c of countriesList"
                  :key="c.value"
                  @click="item.country_code = c.value"
              >
                {{ c.label }}
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="aep-field-name">Country</div>
          <!-- website -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.website"
            />
            <div class="aep-field-name">Website</div>
          </label>
          <!--  Additional Information -->
          <label class="manager-label">
            <textarea class="aep-comment-text" v-model="item.additional_information"></textarea>
            <div class="aep-field-name">Additional Information</div>
          </label>
        </div>
        <!-- interactions -->
        <div class="add-tag-contanier" v-if="catalogTab === 'interactions'">
          <draggable
              class="step-items aep"
              :disabled="item.oldPages"
              v-model="item.pages"
          >
            <div v-for="(s, idx) in item.pages" :key="idx" class="step-item">
              <img alt="img" v-if="s && s.img" :src="s.img"/>
            </div>
          </draggable>
          <div @click.stop="editContent" class="edit-contribution-btn">
            edit
          </div>
          <!-- position -->
          <label>
            <input
                class="aep-search-input"
                type="number"
                disabled
                v-model.number="item.position"
            />
            <div class="aep-field-name ">position</div>
          </label>
          <!-- name -->
          <label class="manager-label">
            <input
                type="text"
                class="input aep manager-input"
                v-model="item.name"
            />
            <div class="aep-field-name ">name</div>
          </label>
        </div>
      </div>
      <button
          :data-is-avalable="popupsVisible"
          id="apply-btn"
          class="popup-btn"
          ref="editBtn"
          @click="onSubmit"
      >
        <div v-show="errorMsg" class="error aep">{{ errorMsg }}</div>
        apply
      </button>
      <!-- comments -->
      <div
          v-if="item.comment && item.comment.length"
          class="aep-field-name  comment-msgs"
          style="text-align: left;"
      >
        <div v-for="(c, idx) of item.comment" :key="idx">
          <div class="comment-data" v-show="serializeComment(c, idx, item)">
            {{ getCorrectDate(c.date) }}
          </div>
          <span class="comment-email">{{ c.user_email }}</span>
          <span class="comment-date"> ({{ getCorrectTime(c.date) }}): </span>
          <span
              class="comment-msg"
              :class="{ green: isAdminsComment(c.user_email) }"
          >{{ c.msg }}</span
          >
        </div>
      </div>
      <div class="popup-close" @click="onHideModal">&times;</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AuthService from "@/services/AuthService";
import {fetchFont, getFontPreview} from "@/helpers/fontsHelper";
import draggable from "vuedraggable";
import contributionsMixin from "@/mixins/contributions";
import {countriesList, countriesOptions} from '@/util/countries'
// ? styles
import "@/assets/css/popups.css";
import "@/assets/css/addEditPopups.css";
import {svgToPng} from "@/util/imageConvertor";

export default {
  name: "editPopup",
  mixins: [contributionsMixin],
  components: {
    draggable
  },
  data() {
    return {
      item: {},
      errorMsg: "",
      ctgsFilterInput: "",
      interactionsFilterInput: "",
      itemComment: "",
      oldStatus: "",
      oldInteraction: ""
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      allCategories: "contributions/allCategories",
      allInteractions: "contributions/allInteractions",
      editContentMode: "workSpace/editContentMode",
      popupsVisible: "workSpace/popupsVisible",
      popupsData: "workSpace/popupsData"
    }),
    catalogTab() {
      return this.popupsData.step;
    },
    countryName() {
      return countriesList[this.item.country_code]
    },
    countriesList() {
      return countriesOptions
    },
    userMemoryLimit: {
      get() {
        return this.item.limits?.storage;
      },
      set(value) {
        this.item.limits.storage = +value;
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.item = JSON.parse(JSON.stringify(this.popupsData.item));
      this.oldStatus = this.item?.status;
      this.oldInteraction = this.item?.interaction?.id;
      if (this.catalogTab === "fonts") {
        this.item.fontFamily = this.item.name;
        fetchFont(this.item.content, this.item.name);
      }
      this.$store.dispatch("contributions/getAllCategoriesAndInteractions");
    },
    async onSubmit() {
      try {
        this.$refs.editBtn.disabled = true;
        const _editFuncName =
            this.catalogTab.slice(0, 1).toUpperCase() + this.catalogTab.slice(1);
        this.addContentManger();
        const resilt = await this[`edit${_editFuncName}`]();
        if (resilt?.error) throw Error(resilt?.error?.message);
        this.onHideModal();
      } catch (error) {
        console.log(error);
        this.errorMsg = error.message;
        this.$refs.editBtn.disabled = false;
      }
    },
    addContentManger() {
      if (this.isEditor && this.item.content_manager)
        this.item.content_manager.email = this.currentUser.email;
    },
    async editUsers() {
      return this.$store.dispatch("contributions/editContentItem", {
        item: this.item,
        type: "users"
      });
    },
    async editCategories() {
      // ? validation
      if (!this.item.name) throw Error("please input name");
      this.addComment();
      const result = await this.$store.dispatch(
          "contributions/editContentItem",
          {
            item: this.item,
            type: "categories"
          }
      );
      this.sendEmailOnItemStatusChange("category");
      return result;
    },
    async editInteractions() {
      // ? validation
      if (!this.item.name) throw Error("please input name");
      const result = await this.$store.dispatch(
          "contributions/editContentItem",
          {
            item: this.item,
            type: "interactions"
          }
      );
      return result;
    },
    async editFonts() {
      // ? validation
      if (!this.item.name) throw Error("please input name");
      this.addComment();
      this.item.oldPreview = this.item.preview;
      this.item.preview = getFontPreview(this.item);
      const result = await this.$store.dispatch(
          "contributions/editContentItem",
          {
            item: this.item,
            type: "fonts"
          }
      );
      this.sendEmailOnItemStatusChange("font");
      return result;
    },
    async editPages() {
      // ? validation
      if (!this.item.name) throw Error("please input name");
      // ? if interaction was chenged update its position
      if (
          !this.oldInteraction ||
          this.item.interaction?.id !== this.oldInteraction
      )
        this.item.interaction_position = null;
      return this.$store.dispatch("contributions/editContentItem", {
        item: this.item,
        type: "pages"
      });
    },
    async editImages() {
      // ? validation
      if (!this.item.categories.length && !this.isEditor)
        throw Error("select at least one category");
      else if (!this.item.name) throw Error("please enter at least one name");
      this.addComment();
      const result = await this.$store.dispatch(
          "contributions/editContentItem",
          {
            item: this.item,
            type: "richImages"
          }
      );
      this.sendEmailOnItemStatusChange("rich_image");
      return result;
    },
    async editTemplates() {
      // ? validation
      if (!this.item.categories.length && !this.isEditor)
        throw Error("select at least one category");
      else if (!this.item.name) throw Error("please input name");
      this.addComment();

      const result = await this.$store.dispatch(
          "contributions/editContentItem",
          {
            item: this.item,
            type: "templates"
          }
      );
      this.sendEmailOnItemStatusChange("template");
      return result;
    },
    async editTexts() {
      // ? validation
      if (!this.item.categories.length && !this.isEditor)
        throw Error("select at least one category");
      else if (!this.item.name) throw Error("please input name");
      this.addComment();

      const result = await this.$store.dispatch(
          "contributions/editContentItem",
          {
            item: this.item,
            type: "texts"
          }
      );
      this.sendEmailOnItemStatusChange("text");
      return result;
    },
    async editContent() {
      if (this.popupsData.environment === "catalog")
        await this.$router.push("/");
      const result = await this.$store.dispatch("workSpace/editContent", {
        editContentMode: true,
        editData: {
          environment: this.popupsData.environment,
          step: this.catalogTab,
          item: this.item
        }
      });
      this.onHideModal();
      return result;
    },
    addComment() {
      if (this.itemComment) {
        this.item.comment.push({
          date: new Date().toISOString(),
          msg: this.itemComment,
          user_email: this.currentUser?.email
        });
      }
    },
    async sendEmailOnItemStatusChange(type) {
      const authorIsEditor = this.item.author?.role === "editor";
      if (this.item.author && this.isEditor) {
        if (this.oldStatus === this.item.status || authorIsEditor) return;
        let image = this.item.name;
        if (type === "template" || type === "text") image = this.item.img;
        AuthService.sendEmail({
          type: `update_${type}_status`,
          author: this.item.author.email,
          image,
          status: this.item.status,
          name: this.item.name,
          locale: "en",
          comment: this.itemComment || ""
        });
      }
    },
    onHideModal() {
      if (
          !this.editContentMode &&
          this.popupsData?.environment === "contribtions"
      ) {
        this.$store.dispatch("workSpace/showPopup", {
          type: "contributionsPopup",
          data: {step: this.catalogTab}
        });
        return;
      }
      this.$store.dispatch("workSpace/showPopup", {clearAll: true});
    },
    goToAddCategory() {
      this.$store.dispatch("workSpace/showPopup", {
        type: "addPopup",
        data: {
          step: "categories",
          // ? data back to edit popups after adding category
          editData: {
            environment: this.popupsData.environment,
            step: this.catalogTab,
            item: this.item
          }
        }
      });
    },
    isCheckedCategory(categoriesList, category) {
      return (
          categoriesList &&
          categoriesList.findIndex(c => category.id === c.id) > -1
      );
    },
    async onUploadImage(e) {
      const file = e.target?.files?.[0] ?? e;
      this.item.newImage = await svgToPng(file);
      this.item.isUpdatedContent = true;
      this.item.newContent = file;
      this.item.size = file.size;
    },
    onUploadFont(e) {
      const file = e.target?.files?.[0] ?? e;
      this.item.newContent = file;
      this.item.size = file.size;
      this.item.isUpdatedContent = true;
      this.item.name = this.item.fontFamily = file.name.replace(
          /\.(t|o)tf/g,
          ""
      );
    },
    getCtgs(ctgsList) {
      if (!ctgsList) return;
      return ctgsList.map(c => c.name).join(", ");
    },
    updateCtgList(itemsList, newCategory) {
      const index = itemsList.findIndex(c => c.id === newCategory.id);
      if (index === -1) itemsList.push(newCategory);
      else itemsList.splice(index, 1);
    },
    isAdminsComment(email) {
      return this.currentUser.email !== email;
    }
  }
};
</script>

<style scoped>
.manager-input {
  max-width: 460px;
  width: 100%;
}

.edit-contribution-btn {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.comment-msgs {
  white-space: pre-line;
  max-height: 200px;
  min-height: 100px;
  overflow: auto;
  margin: 20px 0 20px;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;
  text-align: left;
}

.comment-date,
.comment-email {
  color: #000;
  font-weight: normal;
}

.comment-msg {
  color: #000;
  font-weight: 600;
}

.green {
  color: green;
}

.user-limits-container {
  display: flex;
}

.user-limits-container label {
  margin: 0 15px;
}

.dropdown-menu {
  height: 300px;
  max-width: calc(100vh - 50px);
  overflow: auto;
}
</style>
