import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      allCategories: "contributions/allCategories",
      allInteractions: "contributions/allInteractions",
      popupsData: "workSpace/popupsData"
    }),
    isEditor() {
      return this.currentUser?.role === "editor";
    },
    categoriesListMod() {
      if (!this.ctgsFilterInput) return this.allCategories;
      return this.allCategories.filter(c =>
        c.name.includes(this.ctgsFilterInput)
      );
    },
    interactionsListMod() {
      if (!this.interactionsFilterInput) return this.allInteractions;
      return this.allInteractions.filter(s =>
        s.name.includes(this.interactionsFilterInput)
      );
    }
  },
  methods: {
    getSizeInMBs(size) {
      if (size < 1024) return `${size}bits`;
      if (size < 10240) return `${(size / 1024).toFixed(2)}Kb`;
      return `${(size / 1048576).toFixed(2)}Mb`;
    },
    serializeComment(c, idx, item) {
      if (!idx) return true;
      else
        return (
          this.getCorrectDate(c.date) !==
          this.getCorrectDate(item.comment[idx - 1].date)
        );
    },
    getCorrectDate(date) {
      const _date = new Date(Date.parse(date));
      return new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "short",
        day: "2-digit"
      }).format(_date);
    },
    getCorrectTime(date) {
      const _date = new Date(Date.parse(date));
      return new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit"
      }).format(_date);
    }
  }
};
