// import {readFileAsText} from "@/util/services/files";

const CONTENT_WIDTH = 1000

export const defineImageSizes = (imageFile) => {
    return new Promise((resolve) => {
        const imgUrl = URL.createObjectURL(imageFile)
        const img = new Image();
        img.onload = () => {
            URL.revokeObjectURL(imgUrl)
            resolve({
                width: img.naturalWidth,
                height: img.naturalHeight,
                img,
            })
        }
        img.onerror = (error) => {
            console.log(error)
            URL.revokeObjectURL(imgUrl)
            resolve({
                width: 0,
                height: 0,
                img
            })
        }
        img.src = imgUrl
    })
}

export const svgToPng = async (svgFile) => {
    try {
        if (!svgFile) return

        // define svg size
        const {width: imageWidth, height: imgHeight, img} = await defineImageSizes(svgFile)
        const aspectRatio = CONTENT_WIDTH / imageWidth
        const width = aspectRatio * imageWidth
        const height = aspectRatio * imgHeight

        // const svgString = await readFileAsText(svgFile)
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height)

        return canvas.toDataURL('image/png');
    } catch (e) {
        console.log(e)
        return null
    }
}